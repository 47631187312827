<template>
  <div>

    <b-card>
      <b-row>
        <b-col cols="12">
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :pagination-options="{
              enabled: false,
              dropdownAllowAll: true,
            }"
            :rows="rows"
            :columns="columns"
            :sort-options="{
              enabled: false,
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import API from '@/api'

export const joinToQuery = (key, value) => {
  if (!value) {
    return ''
  }

  return `${key}=${value}`
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: 'Nama Fakultas',
          field: 'nama_fakultas',
        },
        {
          label: 'Count',
          field: 'count',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
    }
  },
  created() {
    this.loadItems()
  },
  methods: {

    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.isLoading = true
        const { data } = await API.election.resulVoteByFaculties()
        this.totalRecords = data.length
        this.rows = data
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
